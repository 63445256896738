<template>
  <div class="home">
    <h1>Welcome to the Home Page</h1>
  </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>