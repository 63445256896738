import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";

let app;

function render(props = {}) {
  const { container } = props;
  app = createApp(App);
  const pinia = createPinia();
  app.use(router);
  app.use(pinia);
  app.mount(container ? container.querySelector("#app") : "#app");
}

async function bootstrap() {
  console.log("子应用启动");
}

async function mount(props) {
  console.log("子应用挂载");
  render(props);
}

async function unmount() {
  console.log("子应用卸载");
  app.unmount();
  app = null;
}

if (!window.__POWERED_BY_QIANKUN__) {
  render();
}

// 子应用名称: subapp
console.log("子应用 subapp 正在初始化");

// 统一导出生命周期函数
export { bootstrap, mount, unmount };
